import * as React from "react";
import Container from "@mui/material/Container";
import { StaticImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ConstructionIcon from "@mui/icons-material/Construction";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ListItem from "@mui/material/ListItem";
import HomeIcon from "@mui/icons-material/Home";

export default function Contact() {
  return (
    <>
      <Container sx={{ padding: 0 }} maxWidth={false} disableGutters>
        <StaticImage
          src="../images/contact.png"
          alt="recycle"
          width={2500}
          // height={500}
          // width={280}
          // sizes="(min-width: 300px) 100vw, 300px"
        />
        <Box
          sx={{
            position: "relative",
            //display: "flex",
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              height: "100%",
              width: "100%",
              bgcolor: "rgba(150, 200, 100, 0.3)", //this is green overlay color
            }}
          />
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={4}>
              <ContactSupportIcon sx={{ fontSize: "5vw" }} />
              <ListItem
                style={{ display: "flex", justifyContent: "center" }}
                button="true"
                key="Enquiry"
                component="a"
                href="mailto:enquiry@reco-system.com"
              >
                <Typography fontSize="1.8vw" variant="h6">
                  enquiry@reco-system.com
                </Typography>
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ConstructionIcon sx={{ fontSize: "5vw" }} />
              <ListItem
                style={{ display: "flex", justifyContent: "center" }}
                button="true"
                key="Enquiry"
                component="a"
                href="mailto:services@reco-system.com"
              >
                <Typography fontSize="1.8vw" variant="h6">
                  services@reco-system.com
                </Typography>
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <PhoneInTalkIcon sx={{ fontSize: "5vw" }} />

              <Typography fontSize="1.8vw" variant="h6">
                +65 6689 9108
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <HomeIcon sx={{ fontSize: "5vw" }} />
              <Typography fontSize="1.8vw" variant="h6">
                10 Anson Road, #20-05, Singapore (079903)
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
